import styled from '@emotion/styled'
import React, { memo } from 'react'

import { Props as RoomProps, Room } from './Room'

export interface Props {
  rooms: RoomProps[]
}

export const RoomsList = memo(function RoomsList({ rooms }: Props) {
  if (rooms.length < 1) {
    return null
  }

  return (
    <Container>
      {rooms.map((item, index) => (
        <Room key={index} {...item} />
      ))}
    </Container>
  )
})

const Container = styled.section``
