import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme'
import { rgba } from 'emotion-rgba'
import React, { memo, useEffect, useState } from 'react'
import { Link } from 'react-scroll'

export interface Props {
  rooms: RoomProps[]
}

export const RoomsNavigation = memo(function RoomsNavigation({ rooms }: Props) {
  if (rooms.length < 1) {
    return null
  }

  const [scroll, setScroll] = useState(false)

  useEffect(() => {
    const onScroll = () => {
      setScroll(window.scrollY > window.innerHeight)
    }

    window.addEventListener('scroll', onScroll)
    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [])

  return (
    <Container>
      <Wrapper className={scroll ? 'fixed' : ''} dial={5} row wrap>
        {rooms.map((item, index) => (
          <Link
            key={index}
            to={`room-${item.id}`}
            spy={true}
            smooth={true}
            offset={-200}
            duration={500}
          >
            {item.title}
          </Link>
        ))}
      </Wrapper>
    </Container>
  )
})

export interface RoomProps {
  id: string
  title: string
}

const Container = styled.section`
  height: 2.125rem;
  position: relative;

  @media (max-width: 767px) {
    display: none;
  }
`

const Wrapper = styled(FlexBox)`
  width: 100%;
  padding-bottom: 0.875rem;
  position: absolute;
  top: 2.125rem;
  left: 0;
  z-index: 2;
  &.fixed {
    animation: RoomsNavFadeInTop 0.3s;
    background: ${({ theme }) => theme.colors.variants.neutralLight4};
    box-shadow: ${rgba(theme.colors.variants.neutralDark1, 0.1)} 0 3px 60px;
    position: fixed;
    top: 4.375rem;
  }

  a {
    color: ${({ theme }) => theme.colors.variants.neutralDark4};
    cursor: pointer;
    font-family: ${({ theme }) => theme.fontFamily.paragraph};
    font-size: 0.75rem;
    font-weight: 600;
    letter-spacing: 0.8px;
    line-height: 1.25rem;
    margin: 0.875rem 1.5rem 0;
    text-transform: uppercase;
    transition: 0.3s ease-in-out;
    &:hover {
      color: ${({ theme }) => theme.colors.variants.primaryDark1};
    }
  }

  @keyframes RoomsNavFadeInTop {
    0% {
      top: -160px;
    }
    100% {
      top: 4.375rem;
    }
  }
`
